<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogTestimony"
        max-width="1400px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form === 'add'"
                >Add Testimony</span
              >
              <span class="headline" v-else>Edit Testimony</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogTestimony = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Tên người viết:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="name_input"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Ảnh người viết:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <ImageCompress :done="getFileAvatar"></ImageCompress>
                      <template v-if="avatar_data != null">
                        <b-img
                          fluid
                          thumbnail
                          :rounded="true"
                          :src="avatar_data"
                        ></b-img>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Nội dung:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><Editor v-model="content_input"></Editor
                    ></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Link testimony</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        outlined
                        v-model="link_testimony_input"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Thời gian phát biểu</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <date-picker
                        type="datetime"
                        class="ml-2"
                        v-model="timestamp_input"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Áp dụng cho màn home:</label
                      ></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-switch
                        class="mt-0 pt-0 ml-2"
                        v-model="is_apply_home"
                        :label="is_apply_home === true ? 'Yes' : 'No'"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Áp dụng các programme:</label
                      ></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        v-model="programme_ids"
                        :items="programmes"
                        item-value="id"
                        item-text="name.vi"
                        filled
                        multiple
                        chips
                        deletable-chips
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6"
                        >Áp dụng các product category:</label
                      ></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <div class="d-flex">
                        <v-autocomplete
                          v-model="product_category_selected"
                          :items="product_category_unselect"
                          item-value="id"
                          item-text="name.vi"
                          filled
                          multiple
                          chips
                          deletable-chips
                        >
                        </v-autocomplete>
                        <v-btn
                          color="cyan"
                          class="ma-2 mt-2 font-weight-bold"
                          outlined
                          small
                          @click="saveProductCategory()"
                        >
                          <v-icon small left>mdi-check</v-icon>
                          Thêm
                        </v-btn>
                      </div>
                      <p v-if="product_category_input.length">
                        (Hãy di chuyển button nếu muốn sắp xếp lại)
                      </p>
                      <div
                        style="background-color: #f2f2f2; width: 100%"
                        class="pt-2 pb-2"
                      >
                        <p
                          v-if="product_category_input.length === 0"
                          class="text-center"
                        >
                          no data
                        </p>
                        <template v-if="product_category_input.length">
                          <draggable :list="product_category_input">
                            <template
                              v-for="(item, i) in product_category_input"
                            >
                              <v-btn
                                style="background-color: #3949ab; color: white"
                                dense
                                class="ml-2 mt-1 mb-1"
                                :key="i"
                              >
                                {{ i + 1 }} - {{ item.name.vi }}
                                <v-icon
                                  dense
                                  color="white"
                                  class="ml-1"
                                  dark
                                  @click="delProductCategory(i)"
                                  >mdi-close</v-icon
                                >
                              </v-btn>
                            </template>
                          </draggable>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditTestimony(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditTestimony(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogTestimony = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ImageCompress from "@/components/image/ImageCompress";
import Editor from "@/components/ckeditor5/Editor";
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import draggable from "vuedraggable";
import Swal from "sweetalert2";

export default {
  name: "TestimonyForm",
  components: {
    Editor,
    ImageCompress,
    Loading: () => import("vue-loading-overlay"),
    draggable,
  },
  props: {
    programmes: {
      type: Array,
      default: () => [],
    },
    product_category_tags: {
      type: Array,
      default: () => [],
    },
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: "",
    },
    offset: {
      type: Number,
      default: 0,
    },
    testimony: {},
  },
  data() {
    return {
      is_call_api: false,
      name_input: "",
      avatar_data: null,
      avatar_file: null,
      programme_ids: [],
      link_testimony_input: "",
      content_input: "",
      is_apply_home: true,
      testimony_id: null,
      timestamp_input: null,

      product_category_unselect: [],
      product_category_selected: [],
      product_category_input: [],
    };
  },
  computed: {
    dialogTestimony: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogTestimony(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      if (this.type_form === "add") {
        this.name_input = "";
        this.avatar_data = null;
        this.avatar_file = null;
        this.content_input = "";
        this.is_apply_home = true;
        this.link_testimony_input = "";
        this.programme_ids = [];
        this.timestamp_input = null;
        this.product_category_unselect = JSON.parse(
          JSON.stringify(this.product_category_tags)
        );
        this.product_category_input = [];
        this.product_category_selected = [];
      } else {
        this.testimony_id = this.testimony.id;
        this.name_input = this.testimony.name;
        this.avatar_data = this.testimony.avatar;
        this.avatar_file = null;
        this.content_input =
          this.testimony.content == null ? "" : this.testimony.content;
        this.link_testimony_input = this.testimony.link_testimony;
        // let apply_in_page = this.testimony.apply_in_page != null ? JSON.parse(this.testimony.apply_in_page) : null;
        this.is_apply_home = this.testimony.is_apply_home === 1;
        this.programme_ids = this.testimony.programmes.map((e) => e.id);
        this.timestamp_input =
          this.testimony.timestamp == null
            ? null
            : new Date(this.testimony.timestamp * 1000);

        this.product_category_unselect = JSON.parse(
          JSON.stringify(this.product_category_tags)
        );
        this.product_category_input = JSON.parse(
          JSON.stringify(this.testimony.product_categories)
        );
        this.product_category_selected = [];
      }
    },
    getFileAvatar(obj) {
      this.avatar_file = obj.file;
      this.avatar_data = obj.blob;
    },
    validateData() {
      let flat = true;
      if (this.name_input === "" || this.name_input == null) {
        this.$toasted.error("Chưa nhập tên người viết !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.content_input == null || this.content_input === "") {
        this.$toasted.error("Chưa nhập nội dung !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnAddOrEditTestimony(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      // let apply_in_page = {
      //   home: this.is_apply_home,
      //   programmes: this.programme_ids,
      // }
      let formData = new FormData();
      formData.append("name", this.name_input);
      formData.append("content", this.content_input);
      formData.append("is_apply_home", this.is_apply_home ? 1 : 0);
      formData.append("programme_ids", this.programme_ids);
      formData.append(
        "product_category_ids",
        this.product_category_input.map((e) => e.id)
      );
      // formData.append('apply_in_page', JSON.stringify(apply_in_page));
      if (this.timestamp_input != null)
        formData.append(
          "timestamp",
          this.convertOjDateToStringDate(this.timestamp_input)
        );
      if (this.avatar_file != null) formData.append("avatar", this.avatar_file);
      if (
        !(this.link_testimony_input === "" || this.link_testimony_input == null)
      )
        formData.append("link_testimony", this.link_testimony_input);
      this.is_call_api = true;
      // if (this.is_call_api) {
      //   console.log(this.convertOjDateToStringDate(this.timestamp_input));
      //   this.is_call_api = false;
      //   return;
      // }
      if (type === 1) {
        formData.append("offset", this.offset);
        ApiService.post("prep-app/testimony", formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetDataTestimony");
              vm.is_call_api = false;
              vm.dialogTestimony = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        formData.append("_method", "PUT");
        ApiService.post("prep-app/testimony/" + vm.testimony_id, formData)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.$emit("resetDataTestimony");
              vm.is_call_api = false;
              vm.dialogTestimony = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    convertOjDateToStringDate(string) {
      let date_ob = new Date(string);
      let year = date_ob.getFullYear();
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      let date = ("0" + date_ob.getDate()).slice(-2);
      let hours =
        date_ob.getHours().toString().length === 1
          ? "0" + date_ob.getHours().toString()
          : date_ob.getHours();
      let minutes = "0" + date_ob.getMinutes();
      let seconds = "0" + date_ob.getSeconds();
      let formattedTime =
        hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
      return year + "-" + month + "-" + date + " " + formattedTime;
    },
    saveProductCategory() {
      for (let i = 0; i < this.product_category_selected.length; i++) {
        let obj_product_category = this.product_category_unselect.filter(
          (e) => e.id === this.product_category_selected[i]
        )[0];
        if (
          this.product_category_input.some(
            (ele) => ele.id === this.product_category_selected[i]
          )
        ) {
          this.$toasted.info(
            "Sản phẩm " +
              obj_product_category.name.vi +
              " đã được thêm trước đó",
            { theme: "toasted-primary", position: "top-right", duration: 4000 }
          );
        } else {
          this.product_category_input.push(obj_product_category);
        }
      }
      this.product_category_selected = [];
    },
    delProductCategory(index) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.product_category_input.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style scoped></style>
