<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Quản lí ý kiến, nhận xét khách hàng
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogTestimony"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm ý kiến, nhận xét</span>
      </v-btn>
      <v-btn
        rounded
        color="cyan"
        class="mb-5 ml-5"
        x-small
        large
        @click="openDialogSortTestimony"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp lại ý kiến, nhận xét</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <b-form-input
              class=""
              style="float: right; width: 300px"
              placeholder="Name, (programme, product category)"
              v-model="input_search"
              @keyup.enter="actionSearchTesttimony()"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Avatar</th>
                    <th scope="col">Name</th>
                    <th scope="col">Nội dung</th>
                    <th scope="col">Hiện thị trên các chương trình</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(testimony, i) in testimonies">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <v-img
                          :src="testimony.avatar"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ testimony.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          style="
                            max-width: max-content;
                            max-height: 300px;
                            overflow: hidden;
                          "
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-html="testimony.content"
                        ></p>
                      </td>
                      <td>
                        <template
                          v-for="(programme, key) in testimony.programmes"
                        >
                          <v-btn
                            :key="key"
                            small
                            color="#00B0FF"
                            style="color: white"
                            class="mt-1 ml-1"
                          >
                            {{ programme.name.vi }}
                          </v-btn>
                        </template>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editTestimony(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteTestimony(testimony.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <TestimonyForm
      :offset="offset"
      :programmes="programmes"
      :show_dialog="dialogTestimony"
      :type_form="type_form"
      :testimony="testimony"
      :product_category_tags="product_categories"
      @setDialog="(e) => (this.dialogTestimony = e)"
      @resetDataTestimony="resetDataTestimony"
    />

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp lại ý kiến , nhận xét</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                      class="list-unstyled drag-css"
                      v-for="(testimony, i) in data_draggable"
                      :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1" style="color: #3699ff">
                          {{ testimony.name }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined text @click="sortTestimony"
              >Update</v-btn
            >
            <v-btn color="red darken-1" text @click="dialogSort = false"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import TestimonyForm from "@/view/pages/Testimony/modal/TestimonyForm";
import Swal from "sweetalert2";
import draggable from "vuedraggable";
export default {
  name: "TestimonyList",
  components: {
    draggable,
    TestimonyForm,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      testimonies: [],
      testimony: {},
      dialogTestimony: false,
      type_form: null,
      programmes: [],
      offset: 0,
      data_draggable: [],
      dialogSort: false,
      product_categories: [],
      input_search: "",
    };
  },
  created() {
    this.getTestimonies();
    this.getProgrammeLimit();
    this.getAllProductCategory();
  },
  methods: {
    async getAllProductCategory() {
      try {
        let res = await ApiService.get("prep-app/product-category/limit");
        if (res.status === 200) {
          this.product_categories = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getTestimonies(query = "") {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/testimony?query=" + query);
        if (res.status === 200) {
          vm.testimonies = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    getProgrammeLimit() {
      let vm = this;
      ApiService.get("prep-app/programme/limit")
        .then(function (res) {
          if (res.status === 200) {
            vm.programmes = res.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    openDiaLogTestimony() {
      this.dialogTestimony = true;
      this.offset = this.testimonies.length;
      this.type_form = "add";
    },
    editTestimony(index) {
      this.testimony = this.testimonies[index];
      this.type_form = "update";
      this.dialogTestimony = true;
    },
    deleteTestimony(testimony_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/testimony/" + testimony_id)
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.resetDataTestimony();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    async resetDataTestimony() {
      this.is_call_api = true;
      await this.getTestimonies();
      this.is_call_api = false;
    },
    openDialogSortTestimony() {
      this.data_draggable = JSON.parse(JSON.stringify(this.testimonies));
      this.dialogSort = true;
    },
    sortTestimony() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want save?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          let items = this.data_draggable.map((item, index) => {
            return {
              testimony_id: item.id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post("prep-app/testimony/sort", data)
            .then(function (res) {
              if (res.status === 200) {
                vm.resetDataTestimony();
                Swal.fire({
                  title: "",
                  text: "The application has been successfully submitted!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                vm.is_call_api = false;
                vm.dialogSort = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              console.log(error);
            });
        }
      });
    },
    actionSearchTesttimony() {
      let query = this.input_search;
      this.getTestimonies(query);
      // let vm = this;
      // if (this.timer_query) {
      //   clearTimeout(this.timer_query);
      //   this.timer_query = null;
      // }
      // this.timer_query = setTimeout(() => {
      //   vm.getTestimonies(query);
      // }, 1200);
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
